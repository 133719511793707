#hero {
  width: 100%;
  height: calc(100vh - 110px);
  padding: 0;
  overflow: hidden;
  background: #000;
  .carousel-item {
    width: 100%;
    height: calc(100vh - 110px);
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    overflow: hidden;
    &::before {
      content: "";
      background-color: transparent;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
    }
  }

  .carousel-inner {
    .carousel-item {
      transition-property: opacity;
      background-position: center top;
      opacity: 0;
    }
    .active.carousel-item-start {
      opacity: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
    }
    .active.carousel-item-end {
      opacity: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
    }
    .active {
      opacity: 1;
      transition: 0.5s;
    }
    .carousel-item-next.carousel-item-start {
      opacity: 1;
      transition: 0.5s;
    }
    .carousel-item-prev.carousel-item-end {
      opacity: 1;
      transition: 0.5s;
    }
    .carousel-item-next {
      left: 0;
      transform: translate3d(0, 0, 0);
    }
    .carousel-item-prev {
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }
  .carousel-control-prev {
    width: 10%;
  }
  .carousel-control-next {
    width: 10%;
  }
  .carousel-control-next-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  .carousel-indicators {
    li {
      cursor: pointer;
      display: none;
    }
  }
}
section {
  padding: 30px 0;
  overflow: hidden;
}
.section-bg {
  background-color: #f5f9fc;
}
.section-title {
  padding-bottom: 30px;
  h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #bb8b63;
  }
  h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 0;
  }
  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
}
.section-title_inner {
  position: relative;
}
.breadcrumbs {
  padding: 15px 0;
  background-color: #f5f9fc;
  min-height: 40px;
  h2 {
    font-size: 24px;
    font-weight: 300;
  }
  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    li + li {
      padding-left: 10px;
      &::before {
        display: inline-block;
        padding-right: 10px;
        color: #6c757d;
        content: "/";
      }
    }
  }
}
.Give_Today {
  padding: 60px;
}
.Treecounts {
  background-image: url("/assets/img/tree_count.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0;
  height: 475px;
}
.Treecounts_inner {
  // text-align: center;
  color: black;
  font-size: 31px;
  font-weight: bold;
  h4 {
    font-size: 2rem;
    font-weight: 700;
  }
}
#Projects {
  .Projects_Details {
    padding: 20px;
  }
  .View_more {
    text-align: center;
    font-weight: 700;
    padding: 15px;
    a {
      color: #bb8b63;
      &:hover {
        color: #8f6a4b;
      }
    }
  }
}
.Projects_Details_inner {
  padding: 30px;
  background: #bb8b63;
  color: #fff;
  text-align: right;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  &::before {
    content: "";
    background-image: url("/assets/img/leaf.png");
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-size: cover;
    opacity: 0.4;
  }
  &:hover {
    background: #8f6a4b;
  }
  h4 {
    font-weight: 700;
  }
}
.location_view {
  min-height: 120px;
}
@media (max-width: 992px) {
  #hero {
    height: calc(100vh - 70px);
    .carousel-item {
      height: calc(100vh - 70px);
    }
    .carousel-content.container {
      padding: 0 50px;
    }
  }
}
@media (max-width: 768px) {
  #hero {
    h2 {
      font-size: 28px;
    }
  }
  .breadcrumbs {
    .d-flex {
      display: block !important;
    }
    ol {
      display: block;
      li {
        display: inline-block;
      }
    }
  }
}
@media (max-height: 500px) {
  #hero {
    height: 120vh;
    .carousel-item {
      height: 120vh;
    }
  }
}
@media (min-width: 1024px) {
  #hero {
    p {
      width: 60%;
    }
    .carousel-control-prev {
      width: 5%;
    }
    .carousel-control-next {
      width: 5%;
    }
  }
}
@media (max-width: 426px) {
  .Give_Today {
    padding: 60px 30px;
  }
}

.Accordian {
  background-color: #bb8b63;
  color: rgb(255, 250, 250);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-family: "Gotham Narrow Book", sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  border: 1px solid #999999;
}

.center {
  margin: auto;
  width: 50%;
  max-width: 400px;
  min-width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
div.loader {
  font-size: 100px;
}

.loaded {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.carousel-content {
  text-align: center;
}

.btn-get-started {
  font-family: "Gotham Narrow Book", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 0;
  background: #bb8b63;
  &:hover {
    background: #8f6a4b;
    color: white;
  }
}

#reactstrap-carousel {
  h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: calc(2rem + 1.25vw);
    font-weight: 300;
  }
  p {
    width: 80%;
    font-size: clamp(18px, calc(15px - 0.5vw), 1rem);
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    color: #fff;
    margin: auto;
  }
}

.bg {
  background-image: url("/assets/img/BGnew.jpg");
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 0;
  // height: 650px;
  min-height: 100vh;
  width: 100%;
  flex: 1;
}

@media (min-width: 900px) {
  .bg {
    height: 100vh;
  }
  .leftsidebackground {
    width: 100%;
    height: 83vh;
    border-radius: 5px;
  }
  .rightcontainer {
    height: 50vh;

    width: 100%;
  }
}

.bg2 {
  // background-image: url("/assets/img/BGmain.png");
  // opacity: 1;
  // background-size: cover;
  // background-repeat: no-repeat;
  background-color: #fff;

  padding: 0px 0;
  // height: 600px;
  // min-height: 100vh;

  width: 100%;
}

@media (min-width: 900px) {
  .bg2 {
    // height: 100vh;
  }
  .leftimage {
    height: 100%;
  }
  // .rightcontainer2 {
  // height: 50vh;
  // }
  .rigthsideb {
    // height: 50vh;
    width: 100%;
  }
  .bg2numsub2 {
    padding-top: 100%;
    padding-left: 75%;
  }
}

.bg3 {
  // background-image: url("/assets/img/BGmain.png");
  // opacity: 1;
  // background-size: cover;
  // background-repeat: no-repeat;

  padding: 0px 0;
  // min-height: 100vh;
  width: 100%;
  flex: 1;
  background-color: #fff;
}

@media (min-width: 900px) {
  .bg3 {
    // height: 100vh;
  }
  .rightcontainer3 {
    // height: 50vh;
    width: 100%;
  }
  .leftsideb {
    // height: 50vh;
    width: 100%;
  }
  .rightimage {
    width: 100%;
    height: 100%;
  }
}

.bg4 {
  padding: 0px 0;

  background-image: url("/assets/img/aboutuslatest.png");
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 900px) {
  .bg4 {
    height: 100vh;
    width: 100%;
  }
  .rightcontainer4 {
    // height: 100vh;
    width: 100%;
    height: 20vh;
  }
  .rightcontainer5 {
    padding-top: 0%;
  }
  .containerleft {
    top: 4533px;
    left: 62px;
    width: 220px;
    height: 259px;
    border-radius: 4px;
  }
}

.bg4image {
  background-image: url("/assets/img/aboutusnew.png");
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}
.bgstatus {
  // background-image: url("/assets/img/status_LI.jpg");
  // opacity: 1;
  // background-size: cover;
  // background-repeat: no-repeat;
  background: white;
  padding: 0px 0;
  // min-height: 100vh;
}

.footText {
  // padding-top: 2%;
  top: 5046px;
  left: 665px;
  // width: 98px;
  height: 16px;
  text-align: center;
}

@media (min-height: 900px) {
  .bgstatus {
    // height: 150vh;
    width: 100%;
  }
}
.textstatus {
  top: 3135px;
  left: 62px;
  width: 400px;
  height: 44px;
  text-align: center;
  font: normal normal 900 32px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #694d35;
  opacity: 1;
  padding-left: 20px;

  padding-top: 30px;
}
/* Split the screen in half */
.split {
  height: 85.7%;
  width: 71.5%;
  position: fixed;
  z-index: 1;
  top: 10;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the left side */
.left {
  left: 0;
  background-color: #111;
  background-image: url("/assets/img/Group 6858@2x.png");
  background-size: contain;
}

// .leftimage{
//   background-image: url("/assets/img/Group 6858@2x.png");
//   background-size:contain;
//   width:419px;
//   height:100%;
//   top:0;
// }

/* Control the right side */
.right {
  right: 0;
  background-color: #f6f4f3;
}

.text-style {
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: justify;
  font: normal normal normal 16px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-style-end {
  // top: 623px;
  // left: 96px;
  // width: 341px;
  // height: 25px;
  text-align: left;
  font: normal normal 900 18px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-bottom: 5%;
}

.rightside {
  text-align: right;

  height: 100%;
  width: 20%;
  float: right;
  padding-right: 430px;
  // margin:auto;
  // display: flex;
}

.leftsidebackground {
  background: rgba(0, 0, 0, 0.5);
  // opacity: 23%;
  width: 100%;
  // height: 20%;
  border-radius: 5px;
}

.rightcontainer {
  width: 100%;
  // padding-left: 190px;
  padding-left: 25%;
  padding-top: 15%;
}

.rightcontainer2 {
  // position: absolute;
  width: 100%;
  height: 100%;
  // top: 834px;
  // left: 694px;
  padding: 5%;
  //  min-height: 100vh;
  // width: 100%;
  // height:100%;
  // background: transparent url("/assets/img/BB1.png") 0% 0% no-repeat padding-box;
  // background-size: cover;
  // background: transparent url("/assets/img/Plant 1.png") 0% 0% no-repeat
  //   padding-box;
  background-image: url("/assets/img/BB1.png");
  background-size: contain;

  background-repeat: no-repeat;
  // width: 100%;
  // min-height: 100vh;
  top: 0;
  right: 0;
  flex: 1;
}

.rightcontainer3 {
  background: transparent url("/assets/img/BB2.png") 0% 0% no-repeat padding-box;
  padding: 5%;
  background-size: cover;

  background-repeat: no-repeat;
  // width: 550px;
  height: 100%;
  top: 0;
  right: 0;
  flex: 1;
}
.rightcontainer4 {
  padding: 10%;
  // padding-top: 35%;
}

.rightcontainer5 {
  padding: 10%;
  // padding-top: 25%;

  // height: 100vh;
  width: 100%;
}

.subcontainer {
  // height: 100vh;
  width: 100%;
  // padding-left: 190px;
  // padding-left: 25%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.desccontainer {
  // height: 100vh;
  width: 100%;
  // padding-left: 190px;
  // padding-left: 25%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.leftside {
  text-align: left;

  height: 490px;
  width: 50%;
  float: left;
  padding-left: 30px;
}

.leftsideo2 {
  text-align: left;
  background-image: url("/assets/img/o2image.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 45%;
  float: left;
  padding-left: 30px;
}

.leftsidesecond {
  text-align: left;
  background-image: url("/assets/img/1.png");
  background-size: contain;
  height: 100%;
  width: 50%;
  float: left;
  padding-left: 30px;
}

.homehead {
  // top: 314px;
  // left: 914px;
  // width: 285px;
  // height: 96px;
  text-align: left;
  font: normal normal 900 70px/60px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  // font-family: "Gotham Narrow Book", sans-serif !important;
  // font-size: 70px;
  // font-weight: 900;
  // margin-bottom: 20px;
  // line-height: 60px;
  // padding-bottom: 0;
  // letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.cellClass{
  color: rgb(211, 235, 255);
}

.homesubhead {
  // top: 421px;
  // left: 919px;
  // width: 400px;
  // height: 78px;
  text-align: left;
  font: normal normal normal 28px/40px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

// #homeheaderNew {
//   top: 0;
//   height: 79px;
//   background: #000000;
//   opacity: 23%;
//   z-index: 997;
//   box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06);
//   justify-content: start;
//   // border-bottom: 3px solid #bb8b63;
//   .logo {
//     h1 {
//       font-size: 28px;
//       margin: 0;
//       padding: 0 0;
//       line-height: 1;
//       font-weight: 400;
//       letter-spacing: 3px;
//       text-transform: uppercase;
//     }
//     img {
//       padding: 0;
//       margin: 0;
//       max-height: 40px;
//     }
//     .a,
//     .a:hover {
//       color: #bb8b63;
//       text-decoration: none;
//     }
//   }
// }

.homeheader-items {
  // position: fixed;
  // top: 0px;
  // right: 0px;
  padding-right: 5px;
  padding-bottom: 10px;
  width: 100%;
  text-align: right;
  align-items: center;
  padding-top: 10px;
  padding-left: 315px;
  .PlantsView_box {
    padding: 0px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.4rem;
    box-shadow: 0px 0px 9px #ddddddad;
    position: relative;
    padding-left: 150px;
    overflow: hidden;
  }
  .PlantsView_box:focus,
  .PlantsView_box:hover {
    border: 1px solid #694d35;
    // box-shadow: 0px 0px 9px rgb(105 77 53 / 36%);
  }

  .PlantsView_box .Plants_img_h {
    width: 150px;
    height: 188px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .PlantsView_box .Plants_img_h img {
    min-height: 188px;
    max-height: 100%;
  }
  .Plants_Short_Dt_inner {
    padding: 10px;
  }
  .Plants_Short_Dt_header {
    font-size: 18px;
    font-weight: 500;
    position: relative;
    padding-right: 45px;
  }
  .Plants_Short_Dt_header h4 {
    font-size: 20px;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .Plants_Short_Dt_header h4 small {
    font-size: 14px;
  }
  .Plants_states {
    position: relative;
  }
  .Plants_states ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .Plants_states ul li {
    padding: 2px 7px;
  }
  .Plants_states .State_counts {
    box-shadow: 0px 1px 4px #ddd;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 27px;
    font-size: 13px;
    text-align: center;
    padding: 3px;
    font-weight: 500;
  }

  .Plants_Short_Dt_footer {
    padding: 5px 10px;
  }
  .Plants_Short_Dt_footer .Single-Project_Wise_data_count .planned,
  .Plants_Short_Dt_footer .Single-Project_Wise_data_count .plantted,
  .Plants_Short_Dt_footer .Single-Project_Wise_data_count .dead {
    font-size: 14px;
  }
  .Plants_perpage {
    display: flex;
    align-items: center;
  }
  .Plants_perpage select {
    width: auto;
    margin-left: 12px;
  }
}

.bg2head {
  padding-top: 17%;
  width: 100%;

  text-align: right;
  // font: normal normal 900 72px/28px "Gotham Narrow Book", sans-serif;
  letter-spacing: 6.2px;
  font-family: "Gotham Narrow Book", sans-serif;
  font-size: 72px;
  font-weight: 900;
  // margin-bottom: 20px;
  line-height: 28px;
  // padding-bottom: 0;
  // letter-spacing: 6.2px;
  color: #694d35;
  opacity: 1;
}

@media (max-width: 900px) {
  .bg2head {
    font-size: 45px;
  }
}

@media (max-width: 900px) {
  .bg2num {
    // font-size: 100px;
  }
}

.bg3head {
  text-align: left;
  font: normal normal 900 62px/28px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #694d35;
  opacity: 1;
  // width: 100%;
  // text-align: left;
  // font: normal normal 900 62px/28px "Gotham Narrow Book", sans-serif;
  // letter-spacing: 6.2px;
  // font-family: "Gotham Narrow Book", sans-serif !important;
  // font-size: 62px;
  // font-weight: 900;
  // margin-bottom: 20px;
  // line-height: 28px;
  padding-bottom: 7%;
  padding-top: 15%;
  // letter-spacing: 6.2px;
  // color: #694d35;
  // opacity: 1;
}

.bg2num {
  width: 100%;

  padding-top: 5%;
  text-align: right;
  // font: normal normal 900 128px/28px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  font-family: "Gotham Narrow Book", sans-serif;
  font-size: 12vmin;
  font-weight: 900;
  // margin-bottom: 20px;
  line-height: 28px;
  // padding-bottom: 0;
  // letter-spacing: 6.2px;
  color: #489747;
  opacity: 1;
}
.bg3num {
  // width: 100%;
  text-align: left;
  font: normal normal 900 133px/28px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  // font-family: "Gotham Narrow Book", sans-serif !important;
  // font-size: 133px;
  // font-weight: 900;
  // margin-bottom: 20px;
  // line-height: 28px;
  // padding-bottom: 0;
  // letter-spacing: 6.2px;
  color: #489747;
  opacity: 1;
}

.bg2numsub {
  width: 100%;

  text-align: right;
  font: normal normal 900 28px/28px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #694d35;
  opacity: 1;
}

.bg2numsub2 {
  padding-top: 100%;
  padding-left: 75%;
  text-align: left;
  font: normal normal 900 28px/28px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #489747;
  opacity: 1;
}

.bg3numsub {
  width: 100%;

  // top: 1381px;
  // left: 734px;
  // width: 542px;
  // height: 85px;
  text-align: left;
  font: normal normal 900 28px/28px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #489747;
  opacity: 1;
}

.bg3desc {
  // width: 480px;

  text-align: justify;
  font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  padding-top: 7%;

  // top: 1381px;
  // left: 734px;
  // width: 542px;
  // height: 107px;
  // text-align: justify;
  // font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  // letter-spacing: 0px;
  // color: #656565;
  // opacity: 1;
}

.termsText {
  // width: 480px;

  text-align: justify;
  font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  padding-left: 4%;
  padding-right: 4%;

  // top: 1381px;
  // left: 734px;
  // width: 542px;
  // height: 107px;
  // text-align: justify;
  // font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  // letter-spacing: 0px;
  // color: #656565;
  // opacity: 1;
}

.privacyText {
  // width: 480px;

  text-align: justify;
  font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  padding-left: 4%;
  padding-right: 4%;

  // top: 1381px;
  // left: 734px;
  // width: 542px;
  // height: 107px;
  // text-align: justify;
  // font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  // letter-spacing: 0px;
  // color: #656565;
  // opacity: 1;
}

.privacyHead {
  // width: 480px;

  // text-align: justify;
  // font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  padding-left: 4%;
  padding-right: 4%;
  text-align: left;
  font: normal normal 900 45px/60px Avenir;
  // letter-spacing: 0px;
  // color: #ffffff;
  opacity: 1;

  // top: 1381px;
  // left: 734px;
  // width: 542px;
  // height: 107px;
  // text-align: justify;
  // font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  // letter-spacing: 0px;
  // color: #656565;
  // opacity: 1;
}

.privacysubhead {
  // width: 480px;

  // text-align: justify;
  // font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  padding-left: 4%;
  padding-right: 4%;
  text-align: left;
  font: normal normal 500 30px/60px Avenir;
  // letter-spacing: 0px;
  // color: #ffffff;
  opacity: 1;

  // top: 1381px;
  // left: 734px;
  // width: 542px;
  // height: 107px;
  // text-align: justify;
  // font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  // letter-spacing: 0px;
  // color: #656565;
  // opacity: 1;
}

.bg2desc {
  width: 100%;

  text-align: justify;
  font: normal normal medium 14px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  padding-top: 7%;
}

@media (min-width: 1920px) {
  .bg2desc {
    padding-top: 15%;
  }
  .bg2numsub {
    padding-top: 5%;
  }
  .bg2num {
    font-size: 9vmin;
  }
}

.rightsideb {
  // text-align: left;
  // height: 100%;
  // width: 20%;
  // float: right;
  // padding-right: 600px;
  // top: 852px;
  // left: 727px;
  // width: 300px;
  // height: 303px;

  // height: 100vh;
  width: 100%;
  padding-top: 20%;
  // opacity: 0.3;

  // margin:auto;
  // display: flex;
}

.rightsided {
  text-align: left;
  height: 100%;
  width: 20%;
  float: right;
  padding-right: 700px;

  // opacity: 0.3;

  // margin:auto;
  // display: flex;
}

.splits {
  position: fixed;
  // width: 70%;
  height: 595px;
}

.lefts {
  left: 0;
  background: #fff;
  padding-left: 4px;
  width: 620px;
  position: absolute;
  height: 595px;
}

.leftimage {
  // background-image: url("/assets/img/1.png");
  // background-size: cover;

  // background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  left: 0;
  flex: 1;
}

.leftso2 {
  // left: 0;

  background-image: url("/assets/img/o2image.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 700px;
  position: absolute;
  height: 595px;
}

.rights {
  right: 0;
  background-color: #f6f4f3;
}

.splits2 {
  position: fixed;
  width: 50%;
  height: 595px;
}

.lefts2 {
  left: 0;
  background-color: #f6f4f3;
}

.rights2 {
  right: 0;
  background: #fff;
  // background-image: url("/assets/img/2.jpg");
  // background-size: contain;
  // background-repeat: no-repeat;

  width: 690px;
  height: 530px;

  position: absolute;
}

.rightimage {
  right: 0;
  flex: 1;
  width: 100%;
  height: 100%;
}

.rights3 {
  right: 0;

  height: 595px;

  position: absolute;
}

.leftsideb {
  // text-align: left;
  // height: 100%;
  // width: 30%;
  // float: left;
  // // padding-right: 600px;
  // top: 1481px;
  // left: 265px;
  // width: 300px;
  // height: 303px;
  width: 100%;
  padding-top: 20%;

  // margin:auto;
  // display: flex;
}

.leftsidec {
  text-align: center;
  height: 100%;
  width: 20%;
  float: left;
  padding-left: 50px;
  padding-top: 100px;
  // margin:auto;
  // display: flex;
}
.rightsidec {
  text-align: right;

  height: 100%;
  width: 45%;
  float: right;
  padding-right: 30px;
}

.aboutussub {
  // width: 100%;

  text-align: left;
  // font: normal normal 900 105px/100px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  font-family: "Gotham Narrow Book", sans-serif;
  font-size: 105px;
  font-weight: 900;
  // margin-bottom: 20px;
  // line-height: 24px;
  // padding-bottom: 0;
  // letter-spacing: 0.6px;
  color: #ffffff;
  opacity: 1;
}

@media (max-width: 900px) {
  .aboutussub {
    font-size: 60px;
  }
}

.aboutussub2 {
  top: 4004px;
  left: 164px;
  width: 309px;
  height: 273px;
  text-align: left;
  font: normal normal 900 120px/32px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  // font-family: "Gotham Narrow Book", sans-serif !important;
  // font-size: 200px;
  // font-weight: 900;
  // margin-bottom: 20px;
  // line-height: 24px;
  // padding-bottom: 0;
  // letter-spacing: 0.6px;
  color: #ffffff;
  opacity: 1;
  padding-left: 100px;
}
.abouthead {
  top: 3941px;
  left: 173px;
  width: 310px;
  height: 109px;
  text-align: left;
  font: normal normal 900 62px/28px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  // font-family: "Gotham Narrow Book", sans-serif !important;
  // font-size: 62px;
  // font-weight: 900;
  // margin-bottom: 20px;
  // line-height: 24px;
  // padding-bottom: 0;
  // letter-spacing: 0.6px;

  color: #ffffff;
  opacity: 1;
}

.cohead {
  flex-direction: row;
  top: 1755px;
  left: 80px;
  width: 93px;
  height: 96px;
  text-align: left;
  font: normal normal 900 70px/32px "Gotham Narrow Book", sans-serif;
  // font-family: "Gotham Narrow Book", sans-serif !important;
  // font-size: 70px;
  // font-weight: 900;
  // margin-bottom: 20px;
  // line-height: 32px;
  // padding-bottom: 0;
  // letter-spacing: 6.2px;

  letter-spacing: 2.1px;
  color: #694d35;
  opacity: 1;
}

.headnum {
  flex-direction: row;
  top: 1755px;
  left: 183px;
  width: 24px;
  height: 96px;
  text-align: left;
  font: normal normal 900 70px/32px "Gotham Narrow Book", sans-serif;
  // font-family: "Gotham Narrow Book", sans-serif !important;
  // font-size: 70px;
  // font-weight: 900;
  // margin-bottom: 10px;
  // line-height: 32px;
  // padding-bottom: 0;
  // letter-spacing: 6.2px;
  letter-spacing: 7px;
  color: #694d35;
  opacity: 1;
}

.corow {
  display: flex;
  flex-direction: row;
}

.corow2 {
  padding-top: 80px;
  display: flex;
  flex-direction: row;
}

.columntext {
  display: flex;
  flex-direction: column;
}

.bg3sub {
  width: 100%;

  text-align: left;
  font: normal normal 900 50px/45px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #489747;
  opacity: 1;
  // top: 1942px;
  // left: 265px;
  // width: 300px;
  // height: 109px;
  // text-align: left;
  // font: normal normal 900 60px/45px "Gotham Narrow Book", sans-serif;
  // letter-spacing: 0px;
  // color: #489747;
  // opacity: 1;
  // padding-left: 3px;
}

.bg5 {
  padding: 10px 0;
  height: 600px;
  width: 100%;

  // top: 2273px;
  // left: -2.029296875px;

  background: transparent linear-gradient(60deg, #b0db84 0%, #618bfd 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
}

.ohead {
  top: 2492px;
  left: 590px;
  width: 58px;
  // height: 96px;
  /* UI Properties */
  text-align: left;
  font: normal normal 900 70px/32px "Gotham Narrow Book", sans-serif;
  letter-spacing: 7px;
  color: #ffffff;
  opacity: 1;
}

.oheadnum {
  top: 2475px;
  left: 659px;
  width: 24px;
  // height: 96px;
  text-align: left;
  font: normal normal 900 70px/32px "Gotham Narrow Book", sans-serif;
  letter-spacing: 7px;
  color: #ffffff;
  opacity: 1;
}

.obignum {
  top: 2655px;
  left: 583px;
  width: 311px;
  // height: 205px;
  text-align: left;
  font: normal normal 900 150px/32px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.osub {
  top: 2722px;
  left: 867px;
  width: 224px;
  // height: 109px;
  text-align: left;
  font: normal normal 900 80px/32px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-right: 800px;
}

.bgtestmonials {
  // height: 80vh;
  padding: 30px 50px 0;
  overflow-x: hidden;
  overflow-y: auto;
  & .testhead {
    font: normal normal 900 32px/22px "Gotham Narrow Book", sans-serif;
    letter-spacing: 0px;
    color: #694d35;
    opacity: 1;
    padding-bottom: 50px;
  }
  & .testimonials-scroll {
    height: 260px;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    white-space: nowrap;
    & .testimonial-card {
      height: 260px;
      width: 500px;
      display: inline-block;
      position: relative;
      &:not(:last-child) {
        margin-right: 24px;
      }
      & .testimonial-image {
        height: 260px;
        width: 200px;
      }
      & .testimonial-data {
        font: normal normal normal 16px/22px "Gotham Narrow Book", sans-serif;
        letter-spacing: 0px;
        color: #36210f;
        padding: 0 16px;
        white-space: pre-wrap;
      }
      & .testimonial-name {
        font: normal normal 900 22px/22px "Gotham Narrow Book", sans-serif;
        letter-spacing: 0px;
        color: #36210f;
        padding: 0 16px;
        white-space: pre-wrap;
        position: absolute;
        bottom: 24px;
      }
    }
  }
}

/* Style the container with a rounded border, grey background and some padding and margin */
.tcontainer {
  box-shadow: 2px 2px 10px #dadada;
  // border: 2px solid #ccc;
  background-color: #ffffff;
  border-radius: 5px;
  // padding: 16px;
  margin: 16px 0;
  width: 60%;
  height: 280px;
}

// @media (min-width: 900px) {
//   .tcontainer img {
//     width: 200px;
//   }
//   .tcontainer{
//     width:400px;
//   }
// }

/* Clear floats after containers */
// .tcontainer::after {
//   content: "";
//   clear: both;
//   display: table;
// }

/* Float images inside the container to the left. Add a right margin, and style the image as a circle */
.tcontainer img {
  float: left;
  margin-right: 20px;
  // border-radius: 50%;
  height: 100%;
  width: 200px;
}

/* Increase the font-size of a span element */
.tcontainer span {
  font-size: 20px;
  margin-right: 15px;
}
.tdesc {
  text-align: left;
  font: normal normal normal 16px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #36210f;
  opacity: 1;
  text-align: justify;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.tname {
  text-align: left;
  font: normal normal 900 22px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #36210f;
  opacity: 1;
  padding: 5%;
}
/* Add media queries for responsiveness. This will center both the text and the image inside the container */
@media (max-width: 900px) {
  .tcontainer {
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .tcontainer img {
    // margin: auto;
    // float: none;
    // display: block;
    height: 300px;
    width: 100%;
  }
  .bgtestmonials {
    // height: 100vh;
  }
}
.fa-arrow-right:before {
  content: "\f061";
  color: rgb(107, 107, 107);
}

.fa-arrow-left:before {
  content: "\f060";

  color: rgb(107, 107, 107);
}

.react-3d-carousel .slider-container .slider-left div {
  position: absolute;
  top: 50%;
  left: 0%;
  margin-top: -20px;
  color: #ffffff;
  transform: translateY(-50%);
  padding: 10px 10px;
  // border-top: 2px solid #fdc84b;
  // border-right: 2px solid #fdc84b;
  // border-bottom: 2px solid #fdc84b;
  // border-left: 2px solid #fdc84b;
}
// .containerleft {
//   top: 4533px;
//   left: 62px;
//   width: 220px;
//   height: 259px;
//   background: #69a131 url("/assets/img/Volunter4.jpg") 0% 0% no-repeat
//     padding-box;
//   background-size: contain;
//   border-radius: 4px;
//   opacity: 0.59;
//   // padding-left: 100px;
// }

// .containerright {
//   top: 4533px;
//   left: 1053px;
//   width: 220px;
//   height: 259px;
//   background: #69a131 url("/assets/img/Volunter4.jpg") 0% 0% no-repeat
//     padding-box;
//   background-size: contain;
//   border-radius: 4px;
//   opacity: 0.59;
//   padding-left: 5px;
// }

// .containercentre {
//   top: 4533px;
//   left: 306px;
//   width: 731px;
//   height: 259px;
//   background: #ffffff 0% 0% no-repeat padding-box;
//   box-shadow: 0px 3px 6px #00000029;
//   border-radius: 4px;
//   opacity: 1;
//   padding-left: 5px;
//   padding-right: 10px;
// }

// .containerimage {
//   top: 4533px;
//   left: 312px;
//   width: 256px;
//   height: 259px;
//   // background: #00000029;
//   background: transparent url("/assets/img/Volunter4.jpg") 0% 0% no-repeat
//     padding-box;
//   background-size: contain;
//   border-radius: 4px;
//   opacity: 1;
//   padding: 5px;
// }

// .fillcontainer {
//   width: 50px;
//   background: #ffffff;
// }

// .texth3 {
//   width: 416px;
//   height: 88px;
//   text-align: justify;
//   font: normal normal normal 16px/22px "Gotham Narrow Book", sans-serif;
//   letter-spacing: 0px;
//   color: #36210f;
//   opacity: 1;
//   padding: 10px;
// }

.testhead {
  top: 4452px;
  left: 62px;
  // width: 182px;
  height: 44px;
  text-align: left;
  font: normal normal 900 32px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  // font-family: "Gotham Narrow Book", sans-serif !important;
  // font-size: 32px;
  // font-weight: 900;
  // margin-bottom: 20px;
  // line-height: 24px;
  // padding-bottom: 0;
  // letter-spacing: 0.6px;
  color: #694d35;
  opacity: 1;
  padding-left: 50px;
  padding-top: 30px;
  padding-bottom: 20%;
}
// .texth2 {
//   width: 102px;
//   height: 30px;
//   /* UI Properties */
//   text-align: center;
//   font: normal normal 900 22px/22px "Gotham Narrow Book", sans-serif;
//   letter-spacing: 0px;
//   color: #36210f;
//   opacity: 1;
//   padding: 10px;
// }

.bgfooter {
  background: transparent url("/assets/img/Footer.png") 0% 0% no-repeat
    padding-box;
  opacity: 1;
  overflow-x: scroll;
}

@media (max-width: 900px) {
  .bgfooter {
    top: 4830px;
    left: -1.5px;
    width: 100%;
    height: 30vh;
  }
}

.footerhead {
  top: 4870px;
  left: 689px;
  // width: 100px;
  height: 22px;
  text-align: left;
  font: normal normal medium 16px/24px "Gotham Narrow Book", sans-serif;
  font-weight: bold;
  letter-spacing: 0.16px;
  color: #182838;
  text-transform: capitalize;
  opacity: 1;
}

.leftsideaboutus {
  text-align: left;

  height: 100%;
  width: 50%;
  float: left;
  padding-left: 30px;
}

.rightsideaboutus {
  text-align: right;

  height: 100%;
  width: 50%;
  float: right;
  padding: 0 50px;
  // margin:auto;
  // display: flex;
}
.statetitle {
  top: 3225px;
  left: 62px;
  width: 215px;
  height: 54px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000015;
  border-radius: 4px;
  opacity: 1;
  padding: 15px;
}

.noofplants {
  top: 3225px;
  left: 292px;
  width: 132px;
  height: 54px;
  /* UI Properties */
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000015;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.cotitle {
  top: 3225px;
  left: 439px;
  width: 132px;
  height: 54px;
  background: #ffa3a3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000015;
  border-radius: 4px;
  opacity: 0.55;
  padding: 10px;
}

.otitle {
  top: 3225px;
  left: 586px;
  width: 132px;
  height: 54px;
  background: #d8dbfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000015;
  border-radius: 4px;
  opacity: 0.55;
  padding: 10px;
}

.corow3 {
  padding-right: 80px;
  display: flex;
  flex-direction: row;
}
.overallheader {
  top: 3242px;
  left: 83px;
  width: 34px;
  height: 19px;
  /* UI Properties */
  text-align: justify;
  font: normal normal 900 10px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #694d35;
  opacity: 1;
  padding-left: 10px;
}

.cocell {
  background: #ffcccc 0% 0% no-repeat padding-box;
  border-radius: 5%;
}

.ag-theme-custom-text-right .ag-header-cell-label{
  text-align: right; // just to make sure that text doesn't go hit itself to next column border
  display: inline-grid;
}

.othercell {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 5%;
  // box-shadow:2px 2px 10px #000;
  box-shadow: 0px 3px 6px #00000015;
}

.All_Graph_box {
  width: 100%;
  & .chart-name {
    font: normal normal medium 14px/22px Avenir;
    letter-spacing: 0px;
    color: #694d35;
    text-align: left;
  }
}
.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.ag-row-selected {
  background-color: #f2ffe5 !important;
  border: 1px solid #99c869 !important;
}

.selected-state {
  font: normal normal 900 22px/22px Avenir;
  letter-spacing: 0px;
  color: #272523;
  text-align: left;
  padding-bottom: 20px;
}

.unselected-radio {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid #767676;
}

.selected-radio {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid #0274ff;
  display: flex;
  justify-content: center;
  align-items: center;
  & .selected-background {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #0274ff;
  }
}

.radio-text {
  font-size: 14px !important;
  color: #7e91a4;
  margin: 0 12px 0 6px;
}
