:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.form-control:focus {
  box-shadow: 0rem 0rem 0rem 0rem transparent;
}

.nav-tabs .nav-link {
  border: none !important;
}
.project-card-container {
  border: 1px solid #bb8b63;
  img {
    height: 140px;
  }

  .grid {
    display: grid;
    grid-template-columns: 35% auto;
  }

  .date-grid {
    display: grid;
    grid-template-columns: 50% auto;
  }

  .status {
    position: absolute;
    bottom: 0px;
    font-size: 13px;
    background: rgba(187, 139, 99, 0.2);
    color: #bb8b63;
    width: 100%;
    padding-left: 10px;
  }
}

.word-break-title {
  display: inline-block;
  width: 250px;
  white-space: normal;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.date-grid-container {
  position: absolute;
  bottom: 13px;
  width: 100%;
}

@media (max-width: 560px) {
  .word-break-title {
    width: 225px !important;
  }

  .nav-projec-title {
    width: 225px !important;
    
  }
  .nav-projec-sub {
    width: 225px !important;
  }
}

@media (max-width: 1400px) {
  .word-break-title {
    width: 200px !important;
  }
  .nav-projec-title {
    width: 200px !important;
  }
  .nav-projec-sub {
    width: 200px !important;
  }
}

@media (max-width: 1400px) {
  .word-break-title {
    width: 160px !important;
  }
  .nav-projec-title {
    width: 160px !important;
  }
  .nav-projec-sub {
    width: 160px !important;
  }
}
.nav-projec-title {
  text-overflow: ellipsis;
  // font-size: 20px;
  font: normal normal 900 20px/30px "Gotham Narrow Book", sans-serif;
}
.nav-projec-sub {
  text-overflow: ellipsis;
  // font-size: 20px;
  font: normal normal 900 15px/30px "Gotham Narrow Book", sans-serif;
}

.ProjectView_box {
  padding: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.4rem;
  box-shadow: 0px 0px 9px #ddddddad;
  position: relative;
  padding-left: 150px;
  overflow: hidden;
}
.ProjectView_box:focus,
.ProjectView_box:hover {
  border: 1px solid #694d35;
  // box-shadow: 0px 0px 9px rgb(105 77 53 / 36%);
}

.ProjectView_box .Project_img_h {
  width: 145px;
  height: 300px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
}
.ProjectView_box .Project_img_h img {
  min-height: 185px;
  max-height: 100%;
}

.pro-3 {
  padding: 1rem !important;
}

.ProjectView_box .Project_img_h {
  width: 150px;
  height: 300px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
}
.ProjectView_box .Project_img_h img {
  min-height: 185px;
  max-height: 100%;
}
.Project_Short_Dt_inner {
  padding: 10px 10px;
}
.Project_Short_Dt_header {
  font-size: 18px;
  font-weight: 500;
}
.Project_Dt_date ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  width: 100%;
}
.Project_Dt_date ul li {
  width: 100%;
}
.Project_Dt_date ul span {
  font-size: 14px;
  color: #a0a0a0;
}

.Project_Short_dt_footer {
  background-color: #ecf4dc;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  // height: 20px;
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    li {
      padding: 0px 8px;
      h6 {
        font-size: 14px;
      }
    }
  }
}
.Project_perpage {
  display: flex;
  align-items: center;
}
.Project_perpage select {
  width: auto;
  margin-left: 12px;
}
.float-left {
  float: left !important;
}
.handSymbol {
  cursor: pointer;
}

.navigationbox {
  position: relative;
  .fa-chevron-left {
    font-size: 18px;
    margin-right: 15px;
  }
  h4 {
    padding: 8px 0px;
    a {
      color: #444;
    }
  }
  .filtterbox {
    position: absolute;
    top: 0px;
    right: 0px;
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      display: flex;
      li {
        padding: 0px 6px;
        .form-control {
          border: none;
          border-bottom: 2px solid #b0b0b0;
          border-radius: 0px;
          &:focus {
            border-color: #694d35;
          }
        }
      }
    }
  }
}

.Plantation_statesBox_head h4 {
  color: #694d35;
  font-size: 28px;
  margin-bottom: 20px;
}
.Over_all_Status {
  color: #929292;
}
.Over_all_Status h4 {
  font-weight: 700;
  color: #000;
}
.Over_all_Status_planned {
  background-color: #e3f9ff;
  border-radius: 4px;
  padding: 20px 15px;
}
.Over_all_Status_plantted {
  background-color: #d3ffd5;
  border-radius: 4px;
  padding: 10px 15px;
}
.Over_all_Status_dead {
  background-color: #ffd3d3;
  border-radius: 4px;
  padding: 10px 15px;
}
.Over_all_Status_graph {
  min-height: 175px;
  padding-left: 10px;
}
.Over_all_Status_graph ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  border-bottom: 1px dashed #ddd;
  display: flex;
  width: 100%;
  min-height: 175px;
}
.Over_all_Status_graph ul li {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
}
.Over_all_Status_graph ul li .planned,
.Over_all_Status_graph ul li .plantted,
.Over_all_Status_graph ul li .dead {
  width: 25px;
  border-radius: 20px;
}
.planned {
  background-color: #9ce4f8;
}
.plantted {
  background-color: #9dfc9f;
}
.dead {
  background-color: #ffd3d3;
}
.Percentage_box {
  background-color: #e8e7f8;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Percentage_box h4 {
  color: #2c14e3;
}
.Percentage_box h4 small {
  font-size: 13px;
}
.totalview_box {
  box-shadow: 0px 02px 5px #ddd;
  border-radius: 4px;
  padding: 15px;
}
.totalview_box ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}
.totalview_box ul li {
  width: 50%;
  padding: 15px 30px;
  position: relative;
}
.totalview_box ul li p {
  margin-bottom: 10px;
}
.totalview_box ul li h4 {
  font-size: 30px;
  margin: 0px;
}
.totalview_box ul li h4 span {
  color: #000;
  font-size: 20px;
}
.totalview_box ul li:nth-child(1) {
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.totalview_box ul li:nth-child(2) {
  border-bottom: 1px solid #f0f0f0;
}
.totalview_box ul li:nth-child(3) {
  border-right: 1px solid #f0f0f0;
}

.totalview_box .Absorbed_box h4 {
  color: #dd103f;
}
.totalview_box .Produced_box h4 {
  color: #29c335;
}
.totalview_box .Plants_box h4 {
  color: #1f765b;
}
.totalview_box .Users h4 {
  color: #694d35;
}
.totalview_box .Plants_box i {
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-size: 75px;
  color: #e7f1eb;
}
.totalview_box .Users i {
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-size: 75px;
  color: #f0f1f5;
}
.Project_Wise_dataBox {
  padding: 0px 15px;
}
.Project_Wise_dataBox_head {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Project_Wise_dataBox_head h6 {
  font-size: 18px;
}
.Project_Wise_dataBox_head ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
}
.Project_Wise_dataBox_head ul li {
  padding: 0px 6px;
  position: relative;
  padding-left: 25px;
}
.Project_Wise_dataBox_head ul li span {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 5px;
}
.Single-Project_Wise_dataBox {
  box-shadow: 0px 2px 5px #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
}
.Single-Project_Wise_dataBox ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}
.Single-Project_Wise_dataBox ul li {
  width: 100%;
  padding: 10px;
}
.Single-Project_Wise_dataBox ul li h5 {
  color: #6a4e36;
  font-weight: 700;
}
.Single-Project_Wise_dataBox ul li p {
  color: #6d6969;
}
.Single-Project_Wise_dataBox ul li h4 {
  font-size: 36px;
  margin: 0px;
  color: #694d35;
}
.Single-Project_Wise_dataBox ul li h4 small {
  font-size: 18px;
}
.Single-Project_Wise_dataBox ul li.project_nameView {
  width: 50%;
  border-right: 1px solid #ddd;
}
.Single-Project_Wise_dataBox ul li.Coordinater_NameView {
  width: 40%;
}
.Single-Project_Wise_dataBox ul li.all_countView {
  width: 20%;
  text-align: right;
}
.Single-Project_Wise_data_count {
  display: flex;
  flex-wrap: wrap;
}
.Single-Project_Wise_data_count .planned,
.Single-Project_Wise_data_count .plantted,
.Single-Project_Wise_data_count .dead {
  border-radius: 20px;
  padding: 2px 20px;
  text-align: right;
  font-weight: 500;
  margin: 2px;
}
.Dist_Data_ListviewBox {
  box-shadow: 0px 2px 5px #ddd;
  border-radius: 4px;
  padding: 10px;
}
.Dist_Data_ListviewBox .Single-Project_Wise_dataBox {
  box-shadow: none;
  padding: 0px;
  margin-bottom: 10px;
}
.Dist_Data_ListviewBox .Single-Project_Wise_dataBox ul li.project_nameView {
  border: none;
  width: 60%;
}
.Dist_Data_ListviewBox .Single-Project_Wise_dataBox ul li h4 {
  font-size: 26px;
  margin: 0px;
  color: #694d35;
}
.Dist_Data_ListviewBox .Single-Project_Wise_dataBox ul li h4 small {
  font-size: 14px;
}
.Top_Volunteers_performbox {
  background-color: #fbfbfb;
  border-radius: 4px;
}
.Top_Volunteers_performbox_body {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}
.Top_Volunteers_img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 5px;
}
.Top_Volunteers_img img {
  width: 100%;
}
.Top_Volunteers_data {
  padding: 6px;
  padding-left: 50px;
}
.Top_Volunteers_performbox_footer {
  padding: 3px;
}
.Top_Volunteers_performbox_footer h4 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 2px;
}
.Top_Volunteers_performbox_footer h4 span {
  font-size: 16px;
  font-weight: 400;
}
.Select_dist {
  border: 1px solid #694d35;
}
.Pincode_listData_view_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.Pincode_listData_view_head a {
  color: red;
  font-weight: 500;
  text-decoration: underline;
  margin-right: 5px;
}
.District_Level_DistributionBox_head h6 {
  font-size: 18px;
}
.District_Level_DistributionBox_body table {
  font-size: 14px;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.District_Level_DistributionBox_body table thead {
  background-color: #694d35;
  color: #fff;
}
.District_Level_DistributionBox_body table tr td {
  border: none;
}
.District_Level_DistributionBox_body table tbody tr td:first-child {
  white-space: nowrap;
}
.District_Level_DistributionBox_body table tr td:last-child {
  width: 36px;
}
.District_Level_DistributionBox_body table thead tr td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.District_Level_DistributionBox_body table thead tr td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.District_Level_DistributionBox_body table tbody tr {
  /* background-color: #fafafa; */
  background-color: #f2f2f2;
}
.District_Level_DistributionBox_body table tbody tr td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.District_Level_DistributionBox_body table tbody tr td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.District_Level_DistributionBox_body table .fa-circle {
  color: #694d35;
  display: none;
}
.District_Level_DistributionBox_body table tbody tr:hover {
  background-color: #f8efe7;
  box-shadow: 0px 2px 5px #ddd;
}
.District_Level_DistributionBox_body table tbody tr:hover .fa-circle {
  display: block;
}
.main_Map_ViewBox {
  padding: 15px;
}
.Map_ViewBox {
  box-shadow: 0px 2px 5px #ddd;
  border-radius: 4px;
}
.Map_ViewBox_head h6 {
  font-size: 18px;
}
.Map_ViewBox_head {
  display: flex;
  justify-content: space-between;
}
.Select_Statesbox .form-control {
  border: none;
  border-bottom: 2px solid #b0b0b0;
  border-radius: 0px;
}
.Select_Statesbox .form-control:focus {
  // box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
  border-color: #694d35;
}
.state_Pro_DataBox .Single-Project_Wise_dataBox {
  background-color: #f8efe7;
  box-shadow: none;
  padding: 0px;
}
.state_Pro_DataBox .Single-Project_Wise_dataBox ul li h4 {
  font-size: 26px;
  margin: 0px;
  color: #694d35;
}

.state_Pro_DataBox .Single-Project_Wise_dataBox ul li h4 small {
  font-size: 14px;
}
.state_Pro_DataBox .Single-Project_Wise_dataBox ul li.project_nameView {
  width: 60%;
  border-right: none;
}
.State_map_viewbox {
  min-height: 370px;
  text-align: center;
  position: relative;
}
.State_map_viewbox img {
  height: 100%;
}
.Map_dist_Count {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  box-shadow: 0px 2px 5px #ddd;
  border-radius: 4px;
  padding: 10px;
  text-align: left;
  background-color: #fff;
  align-items: center;
  transform: translate(253px, 10px);
}
.Map_dist_Count ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  text-align: center;
}
.Map_dist_Count ul li {
  padding: 2px;
  width: 100%;
}
.Map_dist_Count ul li div {
  border-radius: 20px;
  width: 45px;
}
.map_liveBox iframe {
  width: 100%;
  height: calc(100vh - 158px);
  margin-bottom: -5px;
  overflow: hidden;
}
.Top_Volunteers_view {
  padding: 0px 15px;
}
.name-short {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px;
  }
}

@media (max-width: 1024px) {
  .pro-3 {
    padding: 0.5rem !important;
  }
  .ProjectView_box {
    padding-left: 100px;
  }
  .ProjectView_box .Project_img_h {
    width: 100px;
    height: 300px;
  }
  .Project_Dt_date ul li h6 {
    font-size: 14px;
  }
}

@media (max-width: 1025px) {
  .Project_Wise_dataBox {
    margin-bottom: 15px;
  }
  .totalview_box .Users i,
  .totalview_box .Plants_box i {
    z-index: -1;
  }
}

@media (max-width: 768px) {
  .ProjectView_box {
    padding-left: 100px;
  }
  .ProjectView_box .Project_img_h {
    width: 100px;
    height: 300px;
  }
  .Project_Dt_date ul li h6 {
    font-size: 14px;
  }
}

@media (max-width: 430px) {
  .Over_all_Status_graph {
    padding: 0px;
    margin-top: 10px;
  }
  .main_Map_ViewBox {
    padding: 15px 0px;
  }
  .Map_dist_Count {
    transform: translate(135px, 10px);
  }
  .District_Level_DistributionBox_body {
    overflow: auto;
  }
  .totalview_box .Users i,
  .totalview_box .Plants_box i {
    font-size: 50px;
    right: 10px;
  }
  .navigationbox .filtterbox {
    position: relative;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
  }
  .navigationbox .filtterbox ul {
    flex-wrap: wrap;
  }
  .Single-Project_Wise_dataBox ul {
    flex-wrap: wrap;
  }
  .Dist_Data_ListviewBox .Single-Project_Wise_dataBox {
    border-bottom: 1px solid #ddd;
  }
  .Dist_Data_ListviewBox .Single-Project_Wise_dataBox:last-child {
    border-bottom: none;
  }
  .Project_Wise_dataBox_head {
    display: block;
  }
  .Project_Wise_dataBox_head ul {
    margin-top: 10px;
  }
}
