#header {
  height: 70px;
  background: #fff;
  z-index: 997;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06);
  // border-bottom: 3px solid #bb8b63;
  .logo {
    h1 {
      font-size: 28px;
      margin: 0;
      padding: 0 0;
      line-height: 1;
      font-weight: 400;
      letter-spacing: 3px;
      // text-transform: uppercase;
    }
    img {
      padding: 0;
      margin: 0;
      max-height: 40px;
    }
    .a,
    .a:hover {
      color: #bb8b63;
      text-decoration: none;
    }
  }
}

#homeheaderNew {
  top: 0;
  height: 79px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 997;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06);
  justify-content: start;

  // border-bottom: 3px solid #bb8b63;
  .logo {
    h1 {
      font-size: 28px;
      margin: 0;
      padding: 0 0;
      line-height: 1;
      font-weight: 400;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    img {
      padding: 0;
      margin: 0;
      max-height: 40px;
    }
    .a,
    .a:hover {
      color: #bb8b63;
      text-decoration: none;
    }
  }
}
.scrolled-offset {
  margin-top: 70px;
}

.navbar {
  padding: 0;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  li {
    position: relative;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #5c768d;
    white-space: nowrap;
    transition: 0.3s;
    i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
  }
  a:hover,
  .active,
  li:hover > a {
    color: #bb8b63;
  }
  .dropdown {
    ul {
      display: block;
      position: absolute;
      left: 14px;
      top: 100%;
      margin: 0;
      padding: 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      li {
        min-width: 200px;
      }
      a {
        padding: 10px 20px;
        text-transform: none;
        i {
          font-size: 12px;
        }
      }
      a:hover,
      .active:hover,
      li:hover > a {
        color: #428bca;
      }
    }
    &:hover > ul {
      opacity: 1;
      visibility: visible;
    }
    .dropdown {
      ul {
        top: 0;
        left: calc(100% - 30px);
        visibility: hidden;
      }
    }
  }
}

.navbar .dropdown .navbar .dropdown .navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/

.mobile-nav-toggle {
  color: #1f3548;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(31, 53, 72, 0.9);
  transition: 0.3s;
  z-index: 998;
  .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #694d35;
    overflow-y: auto;
    transition: 0.3s;
  }

  a {
    padding: 10px 20px;
    font-size: 15px;
    color: #1f3548;
  }
  .getstarted {
    margin: 15px;
  }
  .dropdown {
    ul {
      position: static;
      display: none;
      margin: 10px 20px;
      padding: 10px 0;
      z-index: 99;
      opacity: 1;
      visibility: visible;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      li {
        min-width: 200px;
      }
      a {
        padding: 10px 20px;
        i {
          font-size: 12px;
        }
      }
    }
  }
}

.navbar-mobile a:hover,
.navbar-mobile .active,
// .navbar-mobile li:target-text > a {
//   color: #1f3548;
// }
.navbar-mobile li:hover > a {
  color: #428bca;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #428bca;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.linktext {
  // top: 19px;
  // left: 56px;
  // width: 85px;
  // height: 26px;
  text-align: left;
  font: normal normal bold 22px/27px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.linktext2 {
  // top: 19px;
  // left: 56px;
  // width: 85px;
  // height: 26px;
  text-align: left;
  font: normal normal 600 22px/27px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.usercontainer {
  height: 100%;
  width: 100%;
  background: #428bca;
}

.username {
  font-size: 28px;
  margin: 0;
  padding: 0 0;
  line-height: 1;
  // font-weight: 400;
  letter-spacing: 0px;
  text-transform: uppercase;
}
