#MyProfile {
  background: #fff;
  padding-left: 5%;
}

.Myprofile_add {
  margin-bottom: 20px;
}

.Myprofile_editer {
  position: absolute;
  top: 0px;
  right: 0px;
}

.Create_ProjectBtn {
  border-radius: 30px;
  background: #bb8b63;
  color: #fff;

  &:hover {
    background: #8f6a4b;
    color: #fff;
  }
}

#ViewProject {
  .Create_ProjectBtn {
    position: absolute;
    right: 0px;
    top: 0px;
  }
}

.ViewProjectCard {
  table.table {
    margin: 0px;

    tr {
      th {
        background: #bb8b63;
        color: #fff;
      }
    }
  }
}

.Action_btn {
  border-radius: 50%;
  background: #bb8b63;
  color: #fff;
  border: none;
  padding: 2px;
  height: 26px;
  width: 27px;
  font-size: 14px;

  &:hover {
    background: #8f6a4b;
  }
}

.planted_ImgBox {
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
      padding: 10px;

      img {
        width: 100%;
      }
    }
  }
}

.planted_ImgBox_view {
  img {
    height: 100%;
  }

  height: 160px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  button.Action_btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}

.comment {
  position: absolute;
  bottom: 5px;
  right: 40px !important;
}

.Top_Per_Volunters {
  h6 {
    font-weight: 600;
    margin-bottom: 15px;
  }
}

.Top_Volunter_Details {
  padding: 10px;
}

.Top_Volunter_Details_inner {
  background: #bb8b63;
  padding: 15px;
  color: #fff;
  border-radius: 5px;
  position: relative;

  &::before {
    content: "";
    // background-image: url("/assets/img/tree_count.jpg");
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    background-size: cover;
    opacity: 0.4;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    display: flex;
    position: relative;

    li {
      width: 100%;

      &:first-child {
        width: 90px;
      }

      &:last-child {
        padding: 15px 0px;
      }
    }
  }

  h6 {
    font-weight: 400;
    margin-bottom: 0px !important;
  }

  h5 {
    font-weight: 600;
    margin-bottom: 2px;
  }

  p {
    margin: 0px;
  }

  h4 {
    font-weight: 600;
    margin: 0px;
    position: relative;
  }
}

.Top_Volunter_img {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
  }
}

#myTotalTab {
  border: none;
  margin-bottom: 18px;
}

#myTotalTab.nav-tabs {
  .nav-link {
    font-weight: 500;
    border: none;

    &:focus {
      border-color: #fff;
    }

    &:hover {
      border-color: #fff;
    }
  }

  .nav-link.active {
    border: none;
    position: relative;

    &::before {
      content: "";
      background: #bb8b63;
      height: 4px;
      position: absolute;
      right: 15px;
      left: 15px;
      bottom: 0px;
    }
  }
}

.All_Graph_box {
  img {
    width: 100%;
  }
}

.kerala_Map {
  height: 500px;
  text-align: center;

  img {
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    margin-bottom: -7px;
  }
}

.Procurement_details_box {
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;

    li {
      padding: 5px;

      &:first-child {
        width: 120px;
      }
    }
  }
}

.DistributionTotalbox {
  h4 {
    color: #bb8b63;
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
  }
}

.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
  position: relative;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ee9944;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 65px;
  // font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

.formAdjustment div .form-group {
  margin-bottom: 0px !important;
}

.cursorCross {
  cursor: not-allowed;
}

// .navActive h3, .dashboardChartTab .active {
//   color: #bb8b63 !important;
//   font-weight: bolder;
// }

.nav-tabs {
  border-bottom: 0 !important;
}

.disp-flex {
  display: flex;
}

.inlineMenu {
  display: flex;
  justify-content: end;
}

.dashboard-page {
  padding: 25px 5vw;

  & .view-text {
    font-size: 15px;
    line-height: 20px;
    font-family: "OpenSans-SemiBold";
    color: #1a1a1a !important;
    margin-left: 15px;
  }

  & .project-name {
    font: normal normal 600 22px/32px Open Sans;
    font-family: "OpenSans-SemiBold";
    color: #694d35;
    margin-bottom: 24px;
    // word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
  }

  & .overall-text {
    font: normal normal normal 14px/16px Open Sans;
    font-family: "OpenSans-Regular";
    letter-spacing: 0px;
    color: #1e2022;
    margin-bottom: 11px;
  }

  & .graph-border {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    border-bottom: 1px dashed #e5e9f3;

    & .planned-graph {
      height: 140px;
      width: 22px;
      background-color: #9ce4f8;
      border-radius: 100px;
    }

    & .planted-graph {
      width: 22px;
      background-color: #9dfc9f;
      border-radius: 100px;
    }

    & .dead-graph {
      width: 22px;
      background-color: #ffd3d3;
      border-radius: 100px;
    }
  }

  & .planned-card,
  & .planted-card,
  & .dead-card {
    height: 65px;
    width: 100%;
    background-color: #e3f9ff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 36px 12px 12px;

    & .planned-text {
      font: normal normal normal 14px/16px Open Sans;
      font-family: "OpenSans-Regular";
      letter-spacing: 0.42px;
      color: #6c6868;
    }

    & .planned-count {
      font: normal normal 600 24px/28px Work Sans;
      font-family: "OpenSans-SemiBold";
      letter-spacing: 0px;
      color: #191616;
    }
  }

  & .planted-card {
    background-color: #d3ffd5;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px !important;
    margin: 12px 6px 0 0;
  }

  & .dead-card {
    background-color: #ffd3d3;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px !important;
    margin: 12px 0 0 6px;
  }

  & .percentage-card {
    height: 53px;
    width: 100%;
    background-color: #dddcf5;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px !important;
    margin-top: 12px;

    & .percentage-text {
      font: normal normal normal 14px/16px Open Sans;
      font-family: "OpenSans-Regular";
      letter-spacing: 0.42px;
      color: #6c6868;
    }

    & .percentage-count {
      font: normal normal 600 24px/28px Work Sans;
      font-family: "OpenSans-SemiBold";
      letter-spacing: 0px;
      color: #2c14e3;
    }
  }

  & .data-container {
    height: 210px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #0000001c;
    padding: 24px;
    margin-top: 24px;
    // background-color: #b7f7bc;
    // background: -webkit-linear-gradient(180deg, #33b9f8 0%, #33b9f8 100%);
    // background: -moz-linear-gradient(180deg, #9dfc9f 0%, #ffd3d3 100%);
    // background: -o-linear-gradient(180deg, #9dfc9f 0%, #ffd3d3 100%);
    background: linear-gradient(180deg, #9ce4f8 0%, #bdfec0 50%, #facbcb 100%);

    & .data-height {
      height: 81px;

      & .center-col {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & .data-text {
          font: normal normal normal 12px/22px Open Sans;
          font-family: "OpenSans-Regular";
          letter-spacing: 0.48px;
          color: #211f1d;
          margin-bottom: 11px;
        }

        & .co2-text,
        & .o2-text,
        & .plants-text,
        & .users-text {
          font: normal normal 600 18px/26px Open Sans;
          font-family: "OpenSans-SemiBold";
          letter-spacing: 1.2px;
          
        }

        & .co2-text {
          color: rgb(0, 0, 0);
        }

        & .o2-text {
          color: #29c335;
        }

        & .plants-text {
          color: #1f765b;
        }

        & .users-text {
          // color: #694d35;
          color: #1f765b;
          margin-top: 20%;
        }

        & .tons-text {
          font: normal normal 600 18px/26px Open Sans;
          letter-spacing: 0.72px;
          color: #211f1d;
          font-family: "OpenSans-SemiBold";
          padding-left: 12px;
        }
      }
    }

    & .bottom-border {
      border-bottom: 1px solid #f0f0f0;
    }

    & .right-border {
      border-right: 1px solid #f0f0f0;
    }
  }

  & .state-data {
    font: normal normal 600 14px/26px Open Sans;
    font-family: "OpenSans-SemiBold";
    letter-spacing: 0.56px;
    color: #211f1d;
    width: unset;
  }

  & .clear-text {
    font: normal normal bold 14px/26px Open Sans;
    font-family: "OpenSans-Bold";
    letter-spacing: 0.56px;
    color: #f8270b;
    text-decoration: underline;
    width: unset;
  }

  & .legend-text {
    font: normal normal normal 12px/12px Open Sans;
    font-family: "OpenSans-Regular";
    letter-spacing: 0.48px;
    color: #211f1d;
    width: unset !important;
  }

  & .planned-legend {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #9ce4f8;
  }

  & .planted-legend {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #9dfc9f;
  }

  & .dead-legend {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #ffd3d3;
  }

  & .states-scroll,
  & .districts-scroll {
    max-height: 368px !important;
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001c;
    border-radius: 6px;
    margin-top: 24px;
  }

  & .districts-scroll {
    max-height: 508px;
  }

  & .no-data {
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 12px/14px Work Sans;
    font-family: "OpenSans-Regular";
    letter-spacing: 0.36px;
    color: #000000;
  }

  & .state-card,
  & .selected-state-card {
    align-items: top;
    padding: 7px 18px;

    & .state-name {
      font: normal normal 600 18px/21px Work Sans;
      font-family: "OpenSans-SemiBold";
      letter-spacing: 0.54px;
      color: #694d35;
      margin-bottom: 3px;
      // word-break: break-all;
      // display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      // overflow: hidden;
      overflow-wrap: break-word;
    }

    & .state-coordinator {
      font: normal normal normal 13px/15px Work Sans;
      font-family: "OpenSans-Regular";
      letter-spacing: 0.39px;
      color: #694d35;
      margin-bottom: 5px;
    }

    & .state-volunteer {
      font: normal normal normal 12px/14px Work Sans;
      font-family: "OpenSans-Regular";
      letter-spacing: 0.36px;
      color: #6c6868;
    }

    & .planned-graph,
    & .planted-graph,
    & .dead-graph {
      height: 20px;
      border-radius: 100px;
      background-color: #9dfc9f;
      text-align: right;
      padding: 3px 10px;
      font: normal normal 600 12px/14px Work Sans;
      font-family: "OpenSans-SemiBold";
      letter-spacing: 0px;
      color: #2c2828;
      margin-bottom: 14px;
    }

    & .planned-graph {
      background-color: #9ce4f8;
      margin-right: 4px;
    }

    & .dead-graph {
      background-color: #ffd3d3;
      margin-left: 4px;
    }

    & .state-percentage {
      font: normal normal 600 24px/28px Work Sans;
      font-family: "OpenSans-SemiBold";
      letter-spacing: 0px;
      color: #694d35;
    }

    &:nth-child(even) {
      background-color: #fafcff;
    }
  }

  & .selected-state-card {
    border: 1px solid #694d35;
    border-radius: 3px;
    margin: 14px 0;
    cursor: auto;
  }

  & .data-header {
    font: normal normal 600 14px/48px Open Sans;
    font-family: "OpenSans-SemiBold";
    letter-spacing: 0px;
    color: #1e2022;
    margin-bottom: 14px;
  }

  & .data-scroll {
    max-height: 210px;
    overflow-y: auto;

    & .data-card {
      width: 100%;
      height: 90px;
      background: #fafafa;
      border-radius: 6px;
      padding: 12px 8px !important;
      margin-bottom: 8px !important;
      position: relative;

      &:nth-child(odd) {
        // margin-left: 8px !important;
      }

      &:nth-child(even) {
        // margin-right: 8px !important;
      }

      & .avatar-image {
        height: 36px !important;
        width: 36px !important;
        border-radius: 50%;
        padding: 0 !important;
      }

      & .content-width {
        width: calc(100% - 40px);
        padding-right: 0 !important;

        & .data-name {
          font: normal normal normal 16px/19px Work Sans;
          font-family: "OpenSans-Regular";
          letter-spacing: 0.48px;
          color: #694d35;
          padding-bottom: 2px;
          // word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          overflow-wrap: break-word;
        }

        & .data-place {
          font: normal normal normal 11px/13px Work Sans;
          font-family: "OpenSans-Regular";
          letter-spacing: 0.33px;
          color: #000000;
          opacity: 0.48;
        }

        & .data-count {
          font: normal normal normal 12px/14px Work Sans;
          font-family: "OpenSans-Regular";
          letter-spacing: 0.36px;
          color: #000000;
          position: absolute;
          bottom: 8px;
        }
      }
    }

    & .no-data {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal normal 12px/14px Work Sans;
      font-family: "OpenSans-Regular";
      letter-spacing: 0.36px;
      color: #000000;
    }
    & .image-card {
      height: 90px;
      margin-bottom: 8px !important;
      position: relative;
      & .image-tile {
        height: 90px;
        width: 100%;
      }
    }
  }

  & .dashboard-dropdown {
    & .css-yk16xz-control,
    & .css-1pahdxg-control {
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #a0a0a0 !important;
      box-shadow: none !important;
    }

    & .css-1uccc91-singleValue {
      font: normal normal 600 12px/17px Open Sans;
      font-family: "OpenSans-SemiBold";
      letter-spacing: 0.12px;
      color: #010101;
    }

    & .css-1okebmr-indicatorSeparator {
      background-color: transparent;
    }
  }

  & .distribution-view {
    & .ag-header {
      margin-top: 12px;
      min-height: 44px !important;
      height: 44px !important;

      & .ag-header-row {
        height: 44px !important;
        background-color: #694d35;

        & .ag-header-cell-text {
          font: normal normal 600 10px/14px Open Sans;
          font-family: "OpenSans-SemiBold";
          letter-spacing: 0px;
          color: #ffffff;
          padding: 0 12px;
        }
      }
    }

    & .ag-body-viewport {
      max-height: 475px;

      & .ag-row {
        cursor: pointer;

        & .ag-cell {
          font: normal normal normal 12px/16px Open Sans;
          font-family: "OpenSans-Regular";
          letter-spacing: 0px;
          color: #1e2022;
          padding: 0 12px;
          opacity: 1;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          background-color: #fafafa;
        }
      }

      & .ag-row-selected {
        border: none !important;
        border-top: 8px solid #ffffff !important;

        & .ag-cell {
          background-color: #f8efe7 !important;
          font-family: "OpenSans-SemiBold" !important;
        }
      }

      & .ag-row,
      & .ag-cell {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & .distribution-table {
      overflow-x: hidden;
      padding-top: 12px;

      & .distribution-header {
        height: 44px;
        width: 100%;
        background-color: #694d35;
        padding: 0 12px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        justify-content: center;

        & .distribution-col {
          align-self: center;

          & .distribution-header-cell {
            width: 150px;
            font: normal normal 600 10px/48px Open Sans;
            font-family: "OpenSans-SemiBold";
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
      }

      & .distribution-body {
        max-height: 475px;
        width: 100%;
        overflow: scroll;

        & .distribution-row {
          height: 44px;
          background-color: #fafafa;
          padding: 0 12px;
          margin-top: 8px !important;
          justify-content: center;

          & .distribution-col {
            align-self: center;

            & .distribution-body-cell {
              font: normal normal normal 12px/16px Open Sans;
              font-family: "OpenSans-Regular";
              letter-spacing: 0px;
              color: #1e2022;
              word-break: break-word;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
        & .selected {
          background-color: #f8efe7 !important;
          box-shadow: 0px 3px 6px #00000029;
        }
        & .state-data,
        & .clear-text {
          font: normal normal normal 12px/16px Open Sans !important;
          font-family: "OpenSans-SemiBold" !important;
          padding: 8px !important;
        }
        & .leader-container {
          height: 197px;
          background-color: #edecf9;
          padding: 0 9px;
          & .no-data {
            height: 100% !important;
          }
          & .leader-header {
            padding: 10px 0;
            border-bottom: 1px solid #e4e3ef;
            & .leader-header-cell {
              font: normal normal normal 10px/12px Work Sans;
              font-family: "OpenSans-Regular";
              letter-spacing: 0.3px;
              color: #000000;
            }
          }
          & .leader-scroll {
            max-height: 142px;
            overflow-y: scroll;
            overflow-x: hidden;
          }
        }
      }
    }
  }
}

.c-pointer {
  cursor: pointer;
}

.ctg-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}